var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{staticClass:"h-100",attrs:{"footer-tag":"footer","footer-bg-variant":"white"},scopedSlots:_vm._u([(_vm.status && !_vm.wishlist && !_vm.reservation)?{key:"footer",fn:function(){return [_vm._v(" Status: "),_c('span',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.carDetail.Status)+" "),_c('b-icon',{attrs:{"id":_vm.iconId + 'stat',"icon":"info-circle"}}),_c('b-tooltip',{attrs:{"target":_vm.iconId + 'stat',"triggers":"hover","placement":"top","variant":"dark"}},[_c('p',{staticClass:"h6"},[_vm._v(" "+_vm._s(_vm.carDetail.Status)+" ")]),_c('p',{staticClass:"small"})])],1)]},proxy:true}:null],null,true)},[_c('router-link',{style:(_vm.notClickable ? 'pointer-events: none' : ''),attrs:{"to":{ name: 'OverView', params: { id: _vm.carDetail.ListingId } }}},[(_vm.imageLink)?_c('div',{staticClass:"box",style:({
        'background-image': 'url(' + _vm.$store.getters.hostURL + _vm.carDetail.Image + ')',
      })}):_c('div',{staticClass:"d-flex align-items-top justify-content-center rounded-top border-bottom",staticStyle:{"height":"194px"}},[_c('b-img',{staticClass:"carImg",attrs:{"src":require('@/assets/img/nophoto.jpg'),"alt":"","blank-color":"black","rounded":"top","fluid-grow":""}})],1)]),(!_vm.imageLink)?_c('span',{staticClass:"h5 comingSoon text-muted"},[_vm._v(_vm._s(_vm.$t("global.label.comingsoon")))]):_vm._e(),(_vm.carDetail.IsReserved)?_c('div',{staticClass:"statusBox bg-white border border-primary rounded text-center"},[_c('span',{staticClass:"text-primary",staticStyle:{"font-size":"12px","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.$t('Overview.Reserved'))+" ")])]):_vm._e(),(_vm.wishlist)?_c('div',{staticClass:"rounded-circle bg-white border heartCircle"},[(_vm.fav)?_c('HeartIcon',{attrs:{"fill-color":"#DB4437"},on:{"click":function($event){return _vm.setWishList()}}}):_vm._e(),(!_vm.fav)?_c('HeartOutlineIcon',{on:{"click":function($event){return _vm.setWishList()}}}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"p-3"},[_c('div',[_c('router-link',{style:(_vm.notClickable ? 'pointer-events: none' : ''),attrs:{"to":{
          name: 'OverView',
          params: { id: _vm.carDetail.ListingId },
        }}},[_c('p',{staticClass:"cardTitle m-0"},[_vm._v(" "+_vm._s(_vm.carDetail.Year)+" "+_vm._s(_vm.carDetail.Make)+" "+_vm._s(_vm.carDetail.Model)+" ")])])],1),_c('div',[_c('p',{staticClass:"small m-0",staticStyle:{"display":"inline-block"}},[(_vm.carDetail.Trim)?_c('b',[_vm._v(" "+_vm._s(_vm.carDetail.Trim)+" ")]):_c('span',[_vm._v(" [trim] ")])])]),_c('div',{staticClass:"mb-2 pt-2 detailText text-muted",staticStyle:{"height":"30px"}},[_c('div',[(_vm.carDetail.Kilometers)?_c('span',[_vm._v(_vm._s(_vm.carDetail.Kilometers))]):_c('span',[_vm._v(" [Mileage] ")]),(_vm.carDetail.Transmission)?_c('span',[_c('span',{staticClass:"divider"},[_vm._v("|")]),_vm._v(" "+_vm._s(_vm.carDetail.Transmission))]):_c('span',[_vm._v(" [Transmission] ")]),(_vm.carDetail.Country)?_c('span',[_c('span',{staticClass:"divider"},[_vm._v("|")]),_vm._v(" "+_vm._s(_vm.carDetail.Country))]):_c('span',[_vm._v(" [Country] ")])])]),_c('div',[_c('div',[(_vm.price)?_c('span',{staticClass:"text-primary pb-0",staticStyle:{"font-size":"18px","font-weight":"bold","white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm.priceData)+" ")]):_vm._e(),_c('p',{staticClass:"labelText"},[_vm._v(" "+_vm._s(_vm.$t("carcard.label.delivery"))+" > "+_vm._s(_vm.carDetail.DeliveryCountry || _vm.$store.state.search.destinationCountry.Name)),_c('br'),_vm._v(" "+_vm._s(_vm.$t("carcard.help.delivery"))+" ")])]),(!_vm.wishlist && !_vm.reservation)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.buttonType),expression:"buttonType"}],staticClass:"py-2 text-right"},[(_vm.buttonType == 'reserve' && _vm.carDetail.IsReserved)?_c('b-button',{staticClass:"cardButtonLabel",style:(_vm.notClickable ? 'pointer-events: none' : ''),attrs:{"pill":"","variant":"secondary","disabled":"","to":{
            name: 'Reservation',
            params: { id: _vm.carDetail.ListingId },
          }}},[_vm._v(_vm._s(_vm.$t("carcard.button.reserveNow")))]):_vm._e(),(
            _vm.buttonType == 'confirm' && _vm.carDetail.CanConfirm && !_vm.carDetail.IsConfirmed
          )?_c('b-button',{staticClass:"cardButtonLabel",attrs:{"pill":"","variant":"warning"}},[_vm._v(_vm._s(_vm.$t("carcard.button.confirmNow")))]):_vm._e(),(
            _vm.buttonType == 'confirm' && !_vm.carDetail.CanConfirm && !_vm.carDetail.IsConfirmed
          )?_c('b-button',{staticClass:"cardButtonLabel",attrs:{"pill":"","variant":"secondary","disabled":""}},[_vm._v(_vm._s(_vm.$t("carcard.button.confirmNow")))]):_vm._e(),(_vm.buttonType == 'confirm' && _vm.carDetail.IsConfirmed)?_c('b-button',{staticClass:"cardButtonLabel",attrs:{"pill":"","disabled":"","variant":"danger"}},[_vm._v(_vm._s(_vm.$t("carcard.button.sold")))]):_vm._e()],1):_vm._e()])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }