<template>
  <!-- buyer dashboard my Watchlist page -->
  <!--
    <TheTitleBar
      sectionTitle="dashboard.label.mnuWatchlist"
      sectionID="buyerWatchList"
      :showCount="true"
      :showValue="false"
      :showFilters="false"
      :showSort="true"
      :showSellButton="false"
      :totalCount="totalCount"
      @qSortValue="sort($event)"
    />
-->
  <div>
    <div class="vehicle-count" v-if="!loading && !error">{{ wishList.length }} {{$t("dashboard.label.vehicles")}}</div>
    <div>
      <b-row align-h="center" v-if="(!wishList || wishList.length < 1) && !loading" class="p-5">
        <h1 class="p-5 my-5 text-secondary text-center">{{$t("dashboard.label.NoVehicleWasFound")}}</h1>
      </b-row>

      <div class="card-wrapper" v-else-if="loading">
        <div
          class="item"
          v-for="(item, index) in new Array(12)"
          :key="'loader' + index + item"
        >
          <v-skeleton-loader
            class="m3x-auto my-2 px-2"
            max-width="100%"
            type="image, article"
          />
        </div>
      </div>
      <div class="card-wrapper" v-else-if="wishList">
        <div
          class="item"
          v-for="carDetail in wishList"
          :key="carDetail.ListingId"
        >
          <div>
            <TheCarCard
              :carDetail="carDetail"
              :price="carDetail.Price"
              :wishlist="true"
              :status="true"
              buttonType="reserve"
              not-clickable
            />
          </div>
          <div class="watchlist-card-footer">
            <b-button @click="reserveCar(carDetail)">{{$t("Overview.ReserveNow")}}</b-button>
            <b-button v-b-modal="'shareModal' + carDetail.ListingId" modal-center>
              <ShareIcon /> {{$t("dashboard.label.ReferToFriends")}}
            </b-button>
            <ShareModal v-if="carDetail" :carDetail="carDetail" />
          </div>
        </div>
      </div>
      <b-row align-h="center" v-else class="p-5">
        <h1 class="p-5 my-5 text-secondary text-center">{{$t("dashboard.label.NoVehicleWasFound")}}</h1>
      </b-row>
    </div>
  </div>
</template>

<script>
// import TheTitleBar from "@/components/dashboard/global/TheTitleBar.vue";
import TheCarCard from "@/components/global/TheCarCard.vue";
import ShareIcon from "vue-material-design-icons/ShareVariantOutline.vue";
import ShareModal from "@/components/global/TheShareModal.vue";
import {mapGetters} from "vuex";
export default {
  components: {
    // TheTitleBar,
    TheCarCard,
    ShareIcon,
    ShareModal
  },
  data() {
    return {
      sortBy: "Defaults",
      sortOrder: 0,
      totalCount: 0,
      loading: true,
      error: false
    };
  },
  methods: {
    reserveCar(carDetail){
      const selectedCountry = this.$store.getters.activeBuyerCountries.find(
          (item) => item.Code === carDetail.DeliveryCountryCode
      );
      this.$store.dispatch("destinationCountry", selectedCountry);
      this.$store.commit("currency", carDetail.Price.CurrencyCode);
      localStorage.setItem("currency", carDetail.Price.CurrencyCode);
      this.$router.push({name: 'OverView', params: {id: carDetail.ListingId}})
    },
    sort(sortOrder) {
      const l = this.$store.getters.wishList;
      if (sortOrder === 6) l.sort((a, b) => (a.l_Year < b.l_Year ? 1 : -1));
      if (sortOrder === 5) l.sort((a, b) => (a.l_Year > b.l_Year ? 1 : -1));
      if (sortOrder === 4)
        l.sort((a, b) => (Number(a.l_Odometer) < Number(b.l_Odometer) ? 1 : -1));
      if (sortOrder === 3)
        l.sort((a, b) => (Number(a.l_Odometer) > Number(b.l_Odometer) ? 1 : -1));
      if (sortOrder === 2)
        l.sort((a, b) =>
          JSON.parse(a.Prices)[0].Price < JSON.parse(b.Prices)[0].Price ? 1 : -1
        );
      if (sortOrder === 1)
        l.sort((a, b) =>
          JSON.parse(a.Prices)[0].Price > JSON.parse(b.Prices)[0].Price ? 1 : -1
        );
    },
  },
  computed: {
    ...mapGetters(["wishList"])
  },
  updated() {
    if (this.wishList) {
      this.totalCount = this.wishList.length;
    }
  },
  beforeMount() {
    this.$store.dispatch("getWishList", this.$store.getters.user.UserId)
      .then(() => {
        this.error = false;
      })
      .catch(() => {
        this.error = true;
      })
      .finally(() => {
        this.loading = false;
      })
    this.$store.dispatch('setDashboardType', 'BuyerDashboard')
    this.sort();
  },
};
</script>

<style lang="scss">
.vehicle-count {
  padding: 24px 0;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: #111111;
}
.card-wrapper {
  display: flex;
  column-gap: 16px;
  row-gap: 16px;
  flex-wrap: wrap;
  .item {
    width: calc((100% / 3) - 11px);
  }
}
.watchlist-card-footer {
  background: #e6f0f9;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding: 16px;
  row-gap: 10px;
  border-bottom: 4px solid #006ac3;
  button,a {
    background: none;
    color: #006ac3;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #006ac3;
    border-radius: 40px;
    font-family: "Inter", sans-serif !important;
    transition: 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background: #006ac3 !important;
      color: white !important;
    }
    svg {
      margin-right: 8px;
    }
  }
}
.share-modal {
  .modal-header {
    position: relative;
    height: 40px;
    background: #006ac3;
    color: white;
    padding: 0 24px;
    display: flex;
    align-items: center;
    h5 {
      font-size: 20px;
      font-family: "Inter", sans-serif !important;
      font-weight: 600;
    }
    .close {
      padding: 0;
      position: absolute;
      top: 16px;
      font-weight: 300;
      opacity: 1;
      color: white;
      width: 30px;
      height: 100%;
    }
  }
  &-body {
    border-top: 1px solid #d0d0d0;
    padding: 24px;
  }
  &-header {
    padding: 24px;
  }
  ul {
    width: 300px;
    margin: auto;
    list-style: none;
    display: flex;
    justify-content: space-between;
  }
  li {
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .icon {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #ededed;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
  }
  .icon-title {
    font-size: 12px;
    color: #363636;
  }
  .share-via {
    font-size: 16px;
    text-align: center;
    margin-bottom: 24px;
  }
  &-title {
    font-size: 18px;
    color: #111111;
    font-weight: 500;
  }
  &-subtitle {
    font-size: 14px;
    color: #404040;
  }
  .modal-body {
    padding: 0;
  }
}

</style>
